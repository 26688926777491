import React, { Component } from "react"
import styled from "styled-components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import { PageTitle, Copy } from "../styles/typography"
import SEO from "../components/seo"

const Container = styled.div`
  padding: 50px 30px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  text-align: left;
`
const FormList = styled.ul`
  list-style: none;
`
const ListItem = styled.li`
  color: #2b2b2b;
  text-decoration: underline;
  font-family: "Articulat";
  font-weight: 500;
  font-size: 0.9rem;
`

class PatientFormsPage extends Component {
  state = {}
  render() {
    const { forms } = this.props.data

    return (
      <Layout>
        <SEO title="First Time Patient & Insurance Claim Forms" />
        <PageHeader image={require("../images/patientforms.jpg")} />
        <Container>
          <PageTitle>Patient Registration Forms</PageTitle>
          <Copy>
            Want to minimize your appointment time? It's simple!
            <br />
            <br />
            <ol>
              <li>Download the form</li>
              <li>Print and fill it out</li>
              <li>Walk right into your appointment!</li>
              <br />
            </ol>
            If you are unsure of any information, don't hesitate to leave it
            out. A Smile Inn representative will assist you with anything you
            need upon your arrival.
          </Copy>
          <Accordion
            preExpanded={["start"]}
            allowMultipleExpanded
            style={{ width: "100%", textAlign: "left" }}
          >
            <AccordionItem uuid="start" style={{ backgroundColor: "#F1F1F1" }}>
              <AccordionItemHeading
                style={{ fontFamily: "Articulat", fontWeight: 600 }}
              >
                <AccordionItemButton>New Patient Forms</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel style={{ background: "#FBFBFB" }}>
                <FormList>
                  <ListItem>
                    <a
                      target="_blank"
                      style={{ color: "inherit" }}
                      href="https://smileinn.cloudaccess.host/wp-content/uploads/2020/01/Smile-Inn-Patient-Registration-and-Health-History-Form-.pdf"
                    >
                      Patient Registration and Health History Form
                    </a>
                  </ListItem>
                </FormList>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading
                style={{ fontFamily: "Articulat", fontWeight: 600 }}
              >
                <AccordionItemButton>Insurance Claim Forms</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel style={{ background: "#FBFBFB" }}>
                <FormList>
                  {forms.edges.map((form, index) => (
                    <ListItem key={index}>
                      <a
                        target="_blank"
                        style={{ color: "inherit" }}
                        href={form.node.acf.form.url.source_url}
                      >
                        {form.node.title}
                      </a>
                    </ListItem>
                  ))}
                </FormList>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Layout>
    )
  }
}

export default PatientFormsPage

export const formsQuery = graphql`
  query($category: String = "Health Claim Form") {
    forms: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          acf {
            form {
              url {
                source_url
              }
            }
          }
        }
      }
    }
  }
`
